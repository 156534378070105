.Virtual{
margin-top: 6rem;
margin-bottom: 6rem;
padding: 2rem;
display: flex;
justify-content: space-around;
color: white;
}

.left{display: flex;
flex-direction: column;
gap: 2rem;
position: relative;
font-size: 2rem;
text-align: left;}

.left>:nth-child(1){
    font-size: 2rem;
    text-transform: uppercase;
    display: block;
    width: 30rem;
    font-weight: bold;
}

.left>:nth-child(2){
    font-size: 1.6rem;
    text-transform: uppercase;
    display: block;
    width: 20rem;
}

.left>:nth-child(3){
    font-size: 1.6rem;
    font-weight: bold;
}



.wrapper{
    width: 20rem;
  position: absolute;
  right: 2.5rem;
 
}


@media screen and (max-width: 856px) {
    .Virtual{
        gap: 2rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
        padding: 1rem;
        margin-left: 2rem;
        margin-right: 2rem;
        text-align: center;
        font-size: 0.8rem;
    
    }
  
.wrapper{
    width: 90%;
   align-self:center;
   left: 1rem;
   
    position: absolute;
   
    
    
}

.left{display: flex;
    flex-direction: column;
    gap: .6rem;
    position: relative;
    font-size: .7rem;
text-align: center;
align-items: center;}


.left>:nth-child(1){
    font-size: 1.6rem;
    text-transform: uppercase;
    display: block;
    width: 30rem;
    font-weight: bold;
}

.left>:nth-child(2){
    font-size: 1.3rem;
    text-transform: uppercase;
    display: block;
    width: 20rem;
    font-weight: 500;
}

.left>:nth-child(3){
    font-size: 1.8rem;
    font-weight: bold;
    text-decoration: underline;
}


}