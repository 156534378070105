.cFooterWrapper{
    width: 100%;
    margin-top: 5rem;
}

.cFooterWrapper>hr{
    width: 100%;
    height: 1px;
    border: none;
    background: white;
    margin-top: 1rem;
}

.cFooter{
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.logo{
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.logo>img{
    width: 4rem;
    height: 4rem;
}

.logo>span{
    font-weight: 400;
    font-size: 1rem;
}

.block{
    display: flex;
flex-direction: column;
gap: 1rem;
max-width: 12rem;
margin-bottom: 2rem;
}

.detail{
    display: flex;
    flex-direction: column;
    width: inherit;
    font-weight: 800;
    font-size: 0.7rem;
    gap: 1rem;
    font-style: italic;

}

.detail>span:nth-of-type(1){
    font-weight: 500;
    font-style: normal;
    font-size: 1.2rem;
    text-decoration: underline;
}

.pngLine:hover{
    cursor: pointer;
}

.pngLine{display: flex;
gap: 1rem;
align-items: center;}

.icon{
    width: 25px;
}

.locaciones{
    display: inline-block ;
    flex-direction: column;
}

.copyRight{
    color: black;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-style: 0.65rem;
    margin-top: 2rem;
    text-align: center;
    color: rgb(204, 230, 59);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 800;
    font-style: italic;
}

@media screen and (max-width: 768px) {
    .cFooter{
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: flex-start;
        font-size: 1.7rem;
        gap: 2rem;
    }
    
}

@media screen and (max-width: 640px) {
    .cFooter{
        justify-content: center;
        flex-wrap: wrap;
        align-items: flex-start;
        font-size: 1.7rem;
    }

    .logo{
        display: none;
    }

    .copyRight{
        font-weight: bold;
        color: rgb(238, 238, 30);
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }
}