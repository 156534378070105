.container{
    padding: 0 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}

.container>:nth-child(1){
    position: absolute;
    width: 6rem;
    right: 21%;
    top: 1.5rem;
    

}

.products{
    display: grid;
    width: 90%;
    grid-template-columns: 25% auto;
 

}

.menu{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-weight: 500;
    font-size: 1.1rem;
  
}

.menu>li:hover{
    color:#ffb124;
    cursor: pointer;
}


.list{display: grid;
grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
height: 15rem;
overflow-y: scroll;
grid-gap: 2rem;
justify-content: space-between;
color: white;}

.product>img{
    top: -5rem;
  margin-left: 0.5rem;
    width: 8rem;
    transform: rotate(-15deg);
}

.list>:nth-child(3){
    font-size: 0.5rem;
}

.product{
    width: 12rem;
    height: 9rem;
    background:linear-gradient(120deg, #e0d0d4 20%, #ffb124 14%, #dfded1 83%);
    position: relative;
    overflow: hidden;
    padding: 0.5rem;
    display: flex;
    border-radius: 1rem;
   
}



@media screen and (max-width: 856px) {

    .container{gap: 1rem;
    }
    
    .container>:nth-child(1){
        display: none;
    }

    .producs{
        grid-template-columns: none;
        gap: 1rem;
    }
.menu{
    flex-direction: row;
}

}

@media screen and (max-width: 640px) {

    .container{
        margin-top: 12rem;
    }
    .menu{
        margin-left: -4rem;
        gap: 2rem;
        flex-wrap: wrap;
        width: 100%;
        font-size: 1.2rem;
        flex-direction: column;
       
    }

    .container>h1{
text-align: center;
margin-top: 10rem;
    }

    .product{
        margin-left: 6rem;
    }

    .product>price{
        display: none;
    }
    
}

