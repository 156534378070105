.container{
    padding: 0rem 2rem;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    color: white;
}

.wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    margin-top: -3.2rem;
}

.wrapper > img{
    width: 8rem;
position: absolute;
bottom: 20px;
}



.cart2 {
    position: absolute;
    bottom: 55%;
    right: 5%;
    display: flex;
    gap: 1rem;
    align-items: center;
    color: rgb(53, 148, 226);
   
}

.cart2>svg{
    width: 30px;
    height: 30px;
    background-color: white;
    padding: 10px;
    border-radius: 50%;
    border: 6px rgb(53, 148, 226) solid;
}




.h_sides{
    display: grid;
    grid-template-rows: 1fr 1fr;
}

.text1{
text-transform:uppercase;
font-size: 0.8rem;
width: min-content;
display: block;
font-weight: 600;
}

.text2{
    display: flex;
    flex-direction: column;
width: min-content;
color: black;    
}

.text2>:first-child{
    font-weight: 800;
    font-size: 1.1rem;
  
}

.text2>:nth-child(2){
    display: block;
    
}

.text2> span{
    color: white;
}
.traffic{
    display: flex;
    flex-direction: column;
    text-align: right;
}

.traffic>:first-child{
    font-weight: 800;
    font-size: 1.6rem;
color: #f7ea38;
}

.customers{
    display: flex;
    flex-direction: column;
    text-align: right;
}

.customers>:first-child{
    font-weight: 800;
    text-align: right;
    font-size: 1.4rem;
}

@media screen and (max-width: 856px) {

.text1, .text2>span:nth-of-type(2){
    font-size: 0.9rem;
    text-align: justify;
}

.text2 > span:nth-of-type(1), .traffic > span:nth-child(1), .customers>span:nth-child(1) {
    font-size: 1.5rem;
    text-align: left;
}

.wrapper>img {
    width: 40%;
    height: 50%;
}
.container{
    grid-template-areas: 
    'left center center'
    'right right right';
}

.container>:first-child{
    grid-area: left;
    grid-template-rows: none;
    gap: 8rem;
}

.container>:nth-child(2){
    grid-area: center;
}

.container>:nth-child(3){
    grid-area: right;
    display: flex;
    justify-content: space-around;
    margin: 2rem 0rem;
}

}


@media screen and (max-width: 640px) {

    .wrapper>img {
        top: 3rem;
        width: 8rem;
        height: 12rem;
    }
    
}
 