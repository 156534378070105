.testimonials{display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 4rem 2rem;}

.wrapper{
    display: grid;
    align-items: flex-end;
    width: 100%;
    grid-template-columns: 1fr 2fr 1fr;
}

.wrapper > img {
    width: 25rem;
    justify-self: center;
}

.container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.container>:nth-child(1){
    font-size: 2rem;
    text-transform: uppercase;
    display: block;
}

.container>:nth-child(2){
    font-size: 0.8rem;
    text-transform: uppercase;
    display: block;
}

.wrapper>:nth-child(3){
    text-align: right;
    font-weight: bold;
}

.reviews{
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
}

.carousel{
    width: 100%;

    }


    .tCarousel{
        padding: 2rem;
    }

    .testimonial{
        display: flex;
        flex-direction: column;
        position: relative;
        color: black;
        background: white;
        border-radius: 0.5rem;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        gap: 1rem;
        box-shadow: 0rem 1rem 3rem -50px #7d7d7d;
        text-align: center;
    }

    .testimonial>img {
        position: absolute!important;
        width: 2rem!important;
        top: 0.1rem!important;
        left: 45%!important;
    }

    .testimonial>span:nth-of-type(1){
        align-items: center;
        font-size: 0.8rem;
        letter-spacing: 1px;
        margin-top: 2rem;
    }

    .testimonial>hr{
        height: 1px;
        width: 80%;
        background: black;
        border: none;
    }

    .testimonial>span:nth-of-type(2){
        font-weight:800;
    }

  @media screen and (max-width:640px) {
    
    .wrapper{
        grid-template-columns: 1fr;
    }
.wrapper>img{
    width: 80vw;
}
.wrapper>:nth-child(3), .wrapper>:nth-child(1) {
    text-align: center;
}

.container, .reviews {
    background: rgb(219, 219, 9);
    color: black;
    text-transform: capitalize;
    text-align: center;
   
}

  }