.container{
    padding: 1.5rem;
    display: flex;
    color:white;
}

.logo{display: flex;
align-items: center;
flex:1;
gap: 0.5 rem;}

.logo>img{width: 5rem;
height: 5rem;}

.logo>span{
    font-weight: 600;
    font-size: 25px
}

.right{
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}

.menu{display: inline-block;
    
gap: 2rem;
list-style: none;
}

.menu>li:hover{
    color: white;
    cursor: pointer;
}

.tire{
    width: 1.5rem;
    height: 1.5rem;
    cursor:none;
    color: black;
}

.bars{display: none;}


@media screen and (max-width: 640px) {
    .right {
position: absolute;
right: 2rem;
z-index: 9999;
background:rgb(231, 231, 29);
color: rgb(15, 1, 1);
border-radius: 5px;
flex-direction: column;
padding: 1rem;
    }


    .menu {
        flex-direction: column;
        margin-left: -2rem;
        display: none;
    }

    .bars {
        display: block;
       
    }
.logo>span{font-size: 1.2rem;}
}

@media screen and (max-width: 856px) {
    .right{gap: 1rem;}




}